import TranslationsList from '~/components/translations-list/translations-list'
import CanvasView from '~/components/canvas-view/canvas-view'
import styles from '~/components/proofreading-view/proofreading-view.module.css'
import { Component, Show, onMount } from 'solid-js'
import { ProofreadingViewProps } from '~/components/proofreading-view/proofreading-view.interfaces'
import SidebarHeader from '~/components/sidebar-header/sidebar-header'
import { loadTranslations } from '~/services/current-document/loading/translations'
import { DocumentProps } from '~/editor/document/document.interfaces'
import { chapter, getChapterData, setChapter } from '~/services/current-document/chapter'
import Document from '~/editor/document/document'
import { getTranslations } from '~/services/database/translations'
import { getComments } from '~/services/database/comments'
import { getTranslationsExport } from '~/services/export/translations'
import SmallButton from '~/components/small-button/small-button'
import ValidateChapterStep from '~/components/validate-chapter-step/validate-chapter-step'
import ExportIcon from '~/assets/icons/export.svg'
import { setLoadingState } from '~/services/loading/loading'
import { translations } from '~/translations'

const ProofreadingView: Component<ProofreadingViewProps> = ({ chapterId }) => {
  onMount(() => {
    setLoadingState({
      loading: true
    })
  })

  const setIsLoading = (value: boolean) => {
    setLoadingState({
      loading: value
    })
  }

  const onDocumentReady = async (props: DocumentProps) => {
    const currentDocument = new Document(props)
    const chapterData = await getChapterData(chapterId)
    setChapter(chapterData)
    const translationsData = await getTranslations(chapterId)
    const commentsData = await getComments(chapterId)
    if (document) {
      loadTranslations({
        currentDocument,
        chapterData,
        translationsData,
        commentsData,
        setIsLoading
      })
    }
  }

  const onExportTranslations = async (event: MouseEvent) => {
    event.stopPropagation()
    setLoadingState({
      loading: true,
      message: translations().loading.exportingTranslations
    })
    try {
      await getTranslationsExport(chapterId)
    } catch (error) {
      console.error(error)
    }
    setLoadingState({
      loading: false
    })
  }

  return (
    <div class={styles.proofreadingView}>
      <CanvasView
        settings={{
          mode: 'proofreading'
        }}
        onReady={onDocumentReady}
      />
      <div class={styles.sideView}>
        <Show when={chapter()}>
          {chapter => (
            <SidebarHeader
              chapter={chapter()}
              mode='proofreading'
            />
          )}
        </Show>
        <TranslationsList mode='proofreading' />
        <ValidateChapterStep
          mode='proofreading'
          aside={(
            <>
              <SmallButton
                icon={ExportIcon}
                onClick={onExportTranslations}
              >
                {translations().general.actions.export}
              </SmallButton>
            </>
          )}
        />
      </div>
    </div>
  )
}

export default ProofreadingView