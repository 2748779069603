import { useParams } from '@solidjs/router'
import ProofreadingView from '~/components/proofreading-view/proofreading-view'
import { ProofreadingParams } from '~/types/routes/series/proofreading'

export default function Proofreading() {
  const { id } = useParams<ProofreadingParams>()
  return (
    <>
      <ProofreadingView chapterId={id} />
    </>
  )
}
